import React from 'react'
import MainContent from '../components/MainContent'

const HomeScreen = () => {
  return (
    <div>
      <MainContent />
    </div>
  )
}

export default HomeScreen