import React from 'react'

const SentScreen = () => {
  return (
    <div>
      <h1>Sent!</h1>

    </div>
  )
}

export default SentScreen
